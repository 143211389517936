<template>
  <v-row justify="center">
    <v-dialog
      v-model="model"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      scrollable
      max-width="1000px"
    >
      <v-card outlined min-height="90vh">
        <v-card-title class="title"
          >{{ title }}
          <v-spacer />
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="py-5">
            <v-select
              :items="$t('absent.month')"
              v-model="idMonth"
              item-value="id"
              item-text="value"
              outlined
              hide-details
              dense
              class="mr-2 select-150"
              @change="changeMonth"
            />
          </div>
          <ReportAbsent
            v-if="idMonth"
            :idMonth="idMonth"
            :year="year"
            :idPerson="person"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from "@/i18n";
export default {
  props: {
    model: {
      type: Boolean,
      required: true
    },
    person: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    month: {
      type: String,
      required: true
    },
    year: {
      type: String,
      required: true
    }
  },
  components: {
    ReportAbsent: () => import("@/components/ReportAbsent")
  },
  watch: {
    model(value) {
      if (value) {
        this.$nextTick(() => {
          this.idMonth = this.month;
          this.idPerson = this.person;
        });
      } else this.idMonth = "";
    }
  },
  data() {
    return {
      idPerson: this.person,
      loadingBtn: false,
      loading: false,
      idMonth: "",
      tableHeaders: [
        {
          text: i18n.t("journal.table.date"),
          align: "left",
          value: "date",
          width: 70
        },
        { text: i18n.t("absent.absent_in"), value: "in", width: 180 },
        {
          text: i18n.t("absent.absent_out"),
          value: "out",
          width: 180
        }
      ],
      dataTable: [],
      infoAbsen: ["Belum Absen / Tidak Masuk", "Libur", "Absen Masuk"],
      colorAbsen: [
        "gradient-error",
        "gradient-primary-dark",
        "gradient-warning"
      ]
    };
  },
  methods: {
    statusAbsent(item, absentType) {
      let result = { info: "", color: "", hours: "" };
      if (item.dayofweek == 1) {
        // freeday
        result.info = i18n.t("absent.freeday");
        result.color = "gradient-primary-dark";
      } else {
        if (absentType == "in") {
          if (item.real_in != null) {
            result.info = i18n.t("absent.absent_in");
            result.color = "gradient-warning";
            result.hours = item.real_in;
          } else {
            result.info = i18n.t("absent.not_yet_absent");
            result.color = "gradient-error";
          }
        }
        if (absentType == "out") {
          if (item.real_out != null) {
            result.info = i18n.t("absent.absent_out");
            result.color = "gradient-warning";
            result.hours = item.real_out;
          } else {
            result.info = i18n.t("absent.not_yet_absent");
            result.color = "gradient-error";
          }
        }
      }
      return result;
    },
    changeMonth(month) {
      this.idMonth = month;
    },
    closeDialog() {
      this.$emit("isOpen");
    }
  }
};
</script>
